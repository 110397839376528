import React from 'react';

const Contact = ({ }) => {

  const submitForm = () => {
    const firstName = document.getElementById('firstName').value;
    const lastName = document.getElementById('lastName').value;
    const company = document.getElementById('company').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const message = document.getElementById('message').value;

    let postData = { firstName, lastName, company, email, phone, message };

    fetch(process.env.REACT_APP_CONTACT_FORM_URL,{
      method: 'POST',
      body: JSON.stringify(postData)
    }).then(()=>{
      document.getElementById('contactFormSuccessNotification').style.display = 'block';
      document.getElementById('contactFormErrorNotification').style.display = 'none';
    }).catch(()=>{
      document.getElementById('contactFormErrorNotification').style.display = 'block';
      document.getElementById('contactFormSuccessNotification').style.display = 'none';
    })
  }

  return (
      <div>
        <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/phone.jpg)', height: 300, paddingTop: 100}} data-overlay={8}>
          <div className="container text-center">
            <div className="row">
              <div className="col-12 col-lg-8 offset-lg-2">
                <h1>Contact Us</h1>
                <p className="fs-18 opacity-70">We're looking forward to hearing from you!</p>
              </div>
            </div>
          </div>
        </header>
        &lt; 
        <main className="main-content">
          <section className="section" style={{paddingTop: 20}}>
            <div className="container">
              {/* <h2 class="text-center">Contact Us</h2> */}
              <p className="lead">If you have any questions, just let us know using the form below. We'll get back to you a.s.a.p.</p>
              <form className="row gap-y" onSubmit={submitForm}>
                <div className="col-12 col-lg-6">
                  <div className="alert alert-success" id="contactFormSuccessNotification" style={{display: 'none'}}>We received your message and we'll get back to you asap.</div>
                <div className="alert alert-danger" id="contactFormErrorNotification" style={{ display: 'none' }}>We could not send your message. Please send us an email at ryheinz@pm.me.</div>
                  <div className="row">
                    <div className="form-group col-12 col-md-6">
                      <input className="form-control form-control-lg" type="text" name="firstName" id="firstName" placeholder="First Name" required="required" />
                    </div>
                    <div className="form-group col-12 col-md-6">
                      <input className="form-control form-control-lg" type="text" name="lastName" id="lastName" placeholder="Last Name" required="required" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-md-12">
                      <input className="form-control form-control-lg" type="text" name="company" id="company" placeholder="Company" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-md-6">
                      <input className="form-control form-control-lg" type="email" name="email" id="email" placeholder="Email" required="required" />
                    </div>
                    <div className="form-group col-12 col-md-6">
                      <input className="form-control form-control-lg" type="text" name="phone" id="phone" placeholder="Phone" />
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea className="form-control form-control-lg" rows={4} placeholder="How can we help you?" name="message" id="message" required="required" defaultValue={""} />
                  </div>
                  <button className="btn btn-lg btn-primary" type="submit">Send message</button>
                </div>
                <div className="col-12 offset-lg-1 col-lg-5 text-center text-lg-left">
                  <p>
                    Schulstr. 01, 19417 Jesendorf, Germany
                  </p>
                  <p>ryheinz@pm.me</p>
                </div>
              </form>
            </div>
          </section>
        </main>
      </div>

  );
}

export default Contact;
