import React from 'react';

const Download = ({ }) => (

<div>
  <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/phone.jpg)', height: 300, paddingTop: 100}} data-overlay={8}>
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h1>Download</h1>
          <p className="fs-18 opacity-70">Download the LeadExporter.io Chrome Extension and get started!</p>
        </div>
      </div>
    </div>
  </header>
  <main className="main-content">
    <section className="section" style={{paddingTop: 20}}>
      <div className="container">
        Hit the button below to download the LeadExporter.io Chrome Extension from the Chrome Store.<br /><br />
          <a href={process.env.REACT_APP_CHROME_EXTENSION_URL} className="btn btn-primary btn-round" target="_blank">Download</a>
        <br /><br />
        Once you've downloaded the goodies, check the <a href="/docs">documentation</a> to get started.
      </div>
    </section>
  </main>
</div>


)

export default Download;
