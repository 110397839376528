import React, {useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import Contact from './components/pages/Contact';
import Docs from './components/pages/Docs';
import DocsGettingStarted from './components/pages/DocsGettingStarted';
import DocsSalesforce from './components/pages/DocsSalesforce';
import Download from './components/pages/Download';
import Faq from './components/pages/Faq';
import Home from './components/pages/Home';
import HowitWorks from './components/pages/HowitWorks';
import NotFound from './components/pages/NotFound';
import Privacy from './components/pages/Privacy';
import Tos from './components/pages/Tos';
import Footer from './components/shared/Footer';
import Navigation from './components/shared/Navigation';
function App() {

  useEffect(() => {
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src=process.env.REACT_APP_Tawk_API_KEY;
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }, [])

  return (
    <>
    <Navigation />
    <Router>
        <Switch>
          <Route path="/" exact component={Home}
          />
          <Route path="/docs" exact component={Docs} />
          <Route path="/docs/installation" exact component={Docs} />
          <Route path="/docs/getting-started" exact component={DocsGettingStarted} />
          <Route path="/docs/custom-integration" exact component={DocsGettingStarted} />
          <Route path="/docs/salesforce" exact component={DocsSalesforce} />
          <Route path="/download" exact component={Download} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/how-it-works" exact component={HowitWorks} />
          <Route path="/privacy" exact component={Privacy} />
          <Route path="/terms-of-service" exact component={Tos} />
          

          <Route path="/faq" exact component={Faq}/>
          <Route component={NotFound} />
        </Switch>
      </Router>
      <Footer />
      </>
  );
}

export default App;
