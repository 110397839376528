import React from 'react';

const DocsGettingStarted = ({ }) => (
<div>
  <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/phone.jpg)', height: 300, paddingTop: 100}} data-overlay={8}>
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h1>Documentation: Getting Started</h1>
          {/* <p class="fs-18 opacity-70">We're looking forward to hearing from you!</p> */}
        </div>
      </div>
    </div>
  </header>
  {/* END Header */}

  <main className="main-content">
    <section className="section" style={{paddingTop: 20}}>
      <div className="container">
        The steps below explain how to start using LeadExporter.io as an end user.<br /><br />
        <h3>Get an account on LeadExporter.io</h3>
        If you are part of an organisation, ask your administrator to create a user for you.<br />
        You'll receive an email with a link. Click the link and complete the signup form.<br />
        <br />
        <br />
        <h3>Install the LeadExporter.io Chrome Extension</h3>
        Download the LeadExporter.io Chrome Extension from the <a href="/download" target="_blank">Download page</a>.
        <br />
        <br />
        <h3>Start the LeadExporter.io Chrome Extension</h3>
        When you visit a person profile on LinkedIn, you'll see a LeadExporter.io login sidebar appear on the right hand side of the page.
        Login with your LeadExporter.io credentials. You're now successfully logged in with LeadExporter.io and will remain logged in until you logout.
        <br /><br />
        <h3>Authorize your user with your CRM system</h3>
        LeadExporter.io needs your permission to connect to your CRM system. LeadExporter.io will ask you to authorize, click <b>Allow</b> to do so.<br />
        Upon successful authorization, refresh the LinkedIn profile you were visiting and from then on LeadExporter.io will be fully operational.
      </div>
    </section>
  </main>
</div>

)

export default DocsGettingStarted;
