import React from "react";

const Home = ({}) => (
  <div>
    <header
      className="header fadeout header-inverse pb-0 h-fullscreen"
      style={{
        backgroundImage: "linear-gradient(to bottom, #243949 0%, #517fa4 100%)",
      }}
    >
      <canvas className="constellation" />
      <div className="container">
        <div className="row h-full">
          <div className="col-12 text-center align-self-center">
            <h1 className="fs-50 fw-600 lh-15 hidden-sm-down">
              Built for{" "}
              <span
                className="text-primary"
                data-type="Business Developers,Recruiters"
              />
            </h1>
            <h1 className="fs-35 fw-600 lh-15 hidden-md-up">
              Built for{" "}
              <span
                className="text-primary"
                data-type="Business Developers,Recruiters"
              />
            </h1>
            <br />
            <p className="fs-20 hidden-sm-down">
              Stop wasting time manually copy pasting profile data from
              LinkedIn.
            </p>
            <p className="fs-16 hidden-md-up">
              Stop wasting time manually copy pasting profile data from
              LinkedIn.
            </p>
            <br />
            <hr className="w-60 hidden-sm-down" />
          </div>
          <div className="col-12 align-self-end text-center pb-70">
            <a
              className="scroll-down-2 scroll-down-inverse"
              href="#"
              data-scrollto="header"
            >
              <span />
            </a>
          </div>
        </div>
      </div>
    </header>
    {/* END Header */}
    {/* Header */}

    <header
      className="header header-inverse bg-fixed"
      id="header"
      style={{
        backgroundImage: "url(/images/how-it-works.jpeg)",
        height: 300,
        paddingTop: 100,
      }}
      data-overlay={8}
    >
      <div className="container text-center">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <h1>Are you still manually copy-pasting data from LinkedIn?</h1>
            <p className="fs-18 opacity-70">Come on, it's 2023.</p>
          </div>
        </div>
      </div>
    </header>
    {/* END Header */}
    {/* Main container */}
    <main className="main-content">
      <section className="section">
        <div className="container">
          <header className="section-header">
            <small>Less admin, more selling</small>
            <h2>Create a contact in your CRM in 2 simple steps</h2>
            <hr />
            <p className="lead">2, not 3.</p>
          </header>
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-7">
              <a
                href="/images/auto-populated-form.png"
                data-lightbox="image-1"
                data-title="The form is auto-populated with relevant info from the LinkedIn profile."
              >
                <img
                  className="rounded shadow-2"
                  src="/images/auto-populated-form.png"
                />
              </a>
              {/* <img class="rounded shadow-2" src="/images/1-create-group.gif" alt="..."> */}
            </div>
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">01</p>
              <h4>Form is auto-populated</h4>
              <p>
                When you visit a person profile on LinkedIn or Sales Navigator,
                LeadExporter will show a form in a sidebar and populate it with
                relevant data from the profile. Active job positions appear as a
                dropdown. Of course, you can overwrite the prepopulated values
                in the form.
              </p>
            </div>
          </div>
          <hr />
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">02</p>
              <h4>Push the button</h4>
              <p>
                With a click on the button, a contact / lead is created in your
                CRM system with the info from the form, without you even having
                to be logged in to the CRM system. Contacts can be linked to
                Company / Account CRM records through a lookup in the form as
                well.
              </p>
            </div>
            <div className="col-12 col-md-7">
              <a
                href="/images/save-contact.png"
                data-lightbox="image-2"
                data-title="Create a contact in your CRM by simply pushing the Save button"
              >
                <img
                  className="rounded shadow-2"
                  src="/images/save-contact.png"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <header className="section-header">
            <small>Less admin, more selling</small>
            <h2>Other benefits</h2>
            <hr />
            {/* <p class="lead">2, not 3.</p> */}
          </header>
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-7">
              <a
                href="/images/known-contact.png"
                data-lightbox="image-3"
                data-title="Easily see if a LinkedIn profile you're visiting is already in your CRM"
              >
                <img
                  className="rounded shadow-2"
                  src="/images/known-contact.png"
                  alt="..."
                />
              </a>
            </div>
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">01</p>
              <h4>Don't waste time on known contacts</h4>
              <p>
                When you visit a person profile on LinkedIn or Sales Navigator,
                LeadExporter will check if the contact already exists in your
                CRM system. If it does, core CRM info (like name, company name
                and recent activity) shows in the sidebar, together with a link
                to the CRM record.
              </p>
            </div>
          </div>
          <hr />
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">02</p>
              <h4>Log InMails as tasks</h4>
              <p>
                Just like with phone and emails, it makes sense to log InMails
                in your CRM system. LeadExporter.io allows you to log an InMail
                message with a single mouse click.
              </p>
            </div>
            <div className="col-12 col-md-7">
              <a
                href="/images/save-tasks.png"
                data-lightbox="image-4"
                data-title="Easily log InMails as tasks in your CRM by clicking 'Create Task' for a message"
              >
                <img
                  className="rounded shadow-2"
                  src="/images/save-tasks.png"
                  alt="..."
                />
              </a>
            </div>
          </div>
          <hr />
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-7">
              <a
                href="/images/mailbox.png"
                data-lightbox="image-5"
                data-title="See highlights from your CRM on the contacts you're sending messages to"
              >
                <img
                  className="rounded shadow-2"
                  src="/images/mailbox.png"
                  alt="..."
                />
              </a>
            </div>
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">03</p>
              <h4>Context in your inbox</h4>
              <p>
                When sending or reading InMail messages, the sidebar will show
                you whether or not the person you are messaging with already
                exists in your CRM system. If so, key info about the contact is
                shown (like name, company name and recent activity), with
                shortcuts to the CRM record and the LinkedIn profile.
              </p>
            </div>
          </div>
          <hr />
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">04</p>
              <h4>Keep your CRM clean</h4>
              <p>
                LeadExporter.io will tell when it finds duplicates in your CRM
                system, and allow you to link a LinkedIn profile to one of the
                records. This will help improve your CRM hygiene.
              </p>
            </div>
            <div className="col-12 col-md-7">
              <a
                href="/images/duplicates.png"
                data-lightbox="image-6"
                data-title="LeadExporter.io will let you know if multiple CRM contacts could match the LinkedIn profile you're visiting"
              >
                <img
                  className="rounded shadow-2"
                  src="/images/duplicates.png"
                  alt="..."
                />
              </a>
            </div>
          </div>
          <hr />
          <div className="row gap-y align-items-center mb-50">
            <div className="col-12 col-md-7">
              <a
                href="/images/dashboard.png"
                data-lightbox="image-7"
                data-title="The form shown on LinkedIn pages is highly configurable"
              >
                <img
                  className="rounded shadow-2"
                  src="/images/dashboard.png"
                  alt="..."
                />
              </a>
            </div>
            <div className="col-12 col-md-5">
              <p className="fs-60 fw-900 opacity-10">05</p>
              <h4>Fully configurable form</h4>
              <p>
                Decide which fields show up in the form, in what order and which
                are required. Add custom fields and choose of multiple supported
                field formats. Simply map the fields to your CRM system fields.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <header className="section-header">
            <h2>Profile info supported by LeadExporter.io</h2>
            <hr />
            <p className="lead">
              To the extend the data is filled out on the LinkedIn profile you
              visit, LeadExporter.io will automatically capture the following
              data:
            </p>
          </header>
          <div className="row gap-y">
            <div className="col-12 col-md-6 col-xl-4">
              <div className="flexbox gap-items-4">
                <div>
                  <i className="fa fa-user fs-25 pt-4" />
                </div>
                <div className="data-details">
                  <h5>Name</h5>
                  <p>First name, last name</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="flexbox gap-items-4">
                <div>
                  <i className="fa fa-black-tie fs-25 pt-4" />
                </div>
                <div className="data-details">
                  <h5>Title</h5>
                  <p>Title and company name for all active positions</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="flexbox gap-items-4">
                <div>
                  <i className="fa fa-at fs-25 pt-4" />
                </div>
                <div className="data-details">
                  <h5>Contact details</h5>
                  <p>
                    Email &amp; phone number (only in Sales Navigator), website
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="flexbox gap-items-4">
                <div>
                  <i className="fa fa-twitter fs-25 pt-4" />
                </div>
                <div className="data-details">
                  <h5>Social media</h5>
                  <p>Twitter handle</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="flexbox gap-items-4">
                <div>
                  <i className="fa fa-globe fs-25 pt-4" />
                </div>
                <div className="data-details">
                  <h5>Location</h5>
                  <p>Country, state, city</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="flexbox gap-items-4">
                <div>
                  <i className="fa fa-language fs-25 pt-4" />
                </div>
                <div className="data-details">
                  <h5>Language proficiency</h5>
                  <p>
                    Language proficiency levels for English, French, German,
                    Spanish, Dutch
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <header className="section-header">
            <small />
            <h2>LinkedIn versions supported by LeadExporter.io</h2>
            <hr />
            <p className="lead">
              No matter which version of LinkedIn you use, LeadExporter.io can
              handle it.
            </p>
          </header>
          <div className="row gap-y">
            <div className="col-12 col-md-6 col-xl-4 feature-1">
              <p className="feature-icon text-dark">
                <i className="fa fa-linkedin" />
              </p>
              <h5>Regular LinkedIn</h5>
            </div>
            <div className="col-12 col-md-6 col-xl-4 feature-1">
              <p className="feature-icon text-info">
                <i className="fa fa-linkedin" />
              </p>
              <h5>LinkedIn Sales Navigator</h5>
            </div>
            <div className="col-12 col-md-6 col-xl-4 feature-1">
              <p className="feature-icon">
                <i className="fa fa-linkedin" />
              </p>
              <h5>LinkedIn Recruiter</h5>
            </div>
          </div>
        </div>
      </section>
    </main>
    {/* END Main container */}
    <section
      className="section text-center py-150"
      style={{ backgroundImage: "url(/images/bg-gift.jpg)" }}
      data-overlay={8}
    >
      <div className="container">
        <h5 className="fs-30 text-white fw-300">
          Get started <strong>free</strong> now
        </h5>
        <br />
        {/* button on mobile */}
        <p className="hidden-sm-up">
          <a
            className="btn btn-lg btn-round btn-success w-400 fs-15 shadow-5"
            href={process.env.REACT_APP_CHROME_EXTENSION_URL}
            target="_blank"
          >
            Download Chrome Extension
          </a>
        </p>
        {/* button on desktop */}
        <p className="hidden-sm-down">
          <a
            className="btn btn-lg btn-round btn-success w-500 fs-17 shadow-5"
            href={process.env.REACT_APP_CHROME_EXTENSION_URL}
            target="_blank"
          >
            Download Chrome Extension
          </a>
        </p>
      </div>
    </section>
    {/* Main container */}
    <main className="main-content"></main>
  </div>
);

export default Home;
