import React from 'react';

const Docs = ({ }) => (
<div>
  <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/phone.jpg)', height: 300, paddingTop: 100}} data-overlay={8}>
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h1>Documentation</h1>
          {/* <p class="fs-18 opacity-70">We're looking forward to hearing from you!</p> */}
        </div>
      </div>
    </div>
  </header>
  <main className="main-content">
    <section className="section" style={{paddingTop: 20}}>
      <div className="container">
        Please check which documentation below is relevant for you:<br /><br />
        <h3>Getting started</h3>
        <h5>Admin</h5>
        <a href="/docs/installation">I'm an administrator of an organisation and want to setup LeadExporter.io for my organisation.</a>
        <br />
        <h5>User</h5>
        <a href="/docs/getting-started">I want to start using LeadExporter.io as an end user.</a>
        <br /><br />
        <h3>Custom Integration</h3>
        <a href="https://leadexporter.gitbook.io/custom-integrations/" target="_blank">I want to setup a custom integration with LeadExporter.io</a>.
        <br /><br />
        <h3>FAQ</h3>
        Check our <a href="/faq">FAQ page</a> for frequently asked questions.
        <br /><br />
      </div>
    </section>
  </main>
</div>


)

export default Docs;
