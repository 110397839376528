import React, { useState, useReducer, useEffect } from 'react';

const Navigation = ({}) => (
    <nav className="topbar topbar-inverse topbar-expand-md topbar-sticky">
        <div className="container">

            <div className="topbar-left">
                <button className="topbar-toggler">&#9776;</button>

            </div>

            <div className="topbar-right">
                <ul className="topbar-nav nav">
                    <li className="nav-item">
                        <a className="nav-link" href="/">Home</a>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" href="/faq">FAQ</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/docs">Documentation</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact</a>
                    </li>
                </ul>

                <div className="d-inline-flex ml-30">
                    <a className="btn btn-sm btn-round btn-primary mr-4" href="/download">Download</a>
                    <a className="btn btn-sm btn-round btn-outline btn-primary mr-4" href="https://app.leadexporter.io/login">Login</a>
                    <a className="btn btn-sm btn-round btn-outline btn-primary hidden-sm-down" href="https://app.leadexporter.io/register">Register</a>
                </div>

            </div>

        </div>
    </nav>
);


export default Navigation;