import React from 'react';

const Faq = ({ }) => (
<div>
  <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/phone.jpg)', height: 300, paddingTop: 100}} data-overlay={8}>
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h1>FAQ</h1>
        </div>
      </div>
    </div>
  </header>
  <main className="main-content">
    <section className="section" style={{paddingTop: 20}}>
      <div className="container">
        <h3>General Questions</h3>
        <h4>Which LinkedIn editions does LeadExporter.io support?</h4>
        LeadExporter.io works for the regular LinkedIn, LinkedIn Sales Navigator and LinkedIn Recruiter.
        <br /><br />
        <h4>Which LinkedIn languages does LeadExporter.io support?</h4>
        LeadExporter.io works for LinkedIn languages English, French, German, Spanish and Dutch.
        <br /><br />
        <h4>Is LeadExporter.io GDPR compliant?</h4>
        LeadExporter.io does not change your data flow, it only makes it more efficient. It does not expose any more data, not does it store any data on any places other than
        the connected CRM system. So if your company is currently GDPR compliant, it will remain GDPR compliant by using LeadExporter.io.
        <br /><br />
        <br />
        <h3>Salesforce Integration Questions</h3>
        <h4>Are lead assignment rules triggered when leads are pushed from LeadExporter to Salesforce?</h4>
        Lead assignment rules are not triggered when LeadExporter creates leads in Salesforce.
        <br /><br />
      </div>
    </section>
  </main>
</div>

)

export default Faq;
