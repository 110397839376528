import React from 'react';

const DocsSalesforce = ({ }) => (

<div>
  <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/phone.jpg)', height: 300, paddingTop: 100}} data-overlay={8}>
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h1>Documentation: Salesforce Integration</h1>
          {/* <p class="fs-18 opacity-70">We're looking forward to hearing from you!</p> */}
        </div>
      </div>
    </div>
  </header>
  <main className="main-content">
    <section className="section" style={{paddingTop: 20}}>
      <div className="container">
        In the Salesforce org you want to connect to LeadExporter.io:<br />
        <ol>
          <li>In Setup, go to App Setup --&gt; Create --&gt; Apps</li>
          <li>Under Connected Apps, click on <b>New</b></li>
          <li>Populate the following values:
            <ul>
              <li>Connected App Name: <b>LeadExporter.io</b></li>
              <li>API Name: <b>LeadExporter_io</b></li>
              <li>Contact Email: <b>info@leadexporter.io</b></li>
              <li>Check <b>Enable OAuth Settings</b></li>
              <li>Callback URL: <b>https://app.leadexporter.io/oauth2/salesforce/cb</b></li>
              <li>Selected OAuth Scopes:</li>
              <li> - <b>Access and manage your data (api)</b></li>
              <li> - <b>Perform requests on your behalf at any time (refresh_token, offline_access)</b></li>
            </ul>
          </li><li>Check <b>Require Secret for Web Server Flow</b></li>
          <li>Click <b>Save</b></li>
          <li>Click <b>Continue</b></li>
        </ol>
      </div>
    </section>
  </main>
</div>


)

export default DocsSalesforce;
