import React from 'react';

const HowitWorks = ({ }) => (
 <div>
  <header className="header header-inverse bg-fixed" style={{backgroundImage: 'url(/images/how-it-works.jpeg)', height: 300, paddingTop: 100}} data-overlay={8}>
    <div className="container text-center">
      <div className="row">
        <div className="col-12 col-lg-8 offset-lg-2">
          <h1>Are you still manually copy-pasting data from LinkedIn?</h1>
          <p className="fs-18 opacity-70">Come on, it's 2018.</p>
        </div>
      </div>
    </div>
  </header>
  <main className="main-content">
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>Less admin, more selling</small>
          <h2>Create a contact in your CRM in 2 simple steps</h2>
          <hr />
          <p className="lead">2, not 3.</p>
        </header>
        <div className="row gap-y align-items-center mb-50">
          <div className="col-12 col-md-7">
            <img className="rounded shadow-2" src="/images/auto-populated-form.png" />
            {/* <img class="rounded shadow-2" src="/images/1-create-group.gif" alt="..."> */}
          </div>
          <div className="col-12 col-md-5">
            <p className="fs-60 fw-900 opacity-10">01</p>
            <h4>Form gets auto-populated</h4>
            <p>
              When you visit a person profile on LinkedIn or Sales Navigator, LeadExporter will show a form in a sidebar
              and populate it with relevant data from the profile. Active job positions appear as a dropdown. Of course, you can overwrite the prepopulated values in the form.
            </p>
          </div>
        </div>
        <hr />
        <div className="row gap-y align-items-center mb-50">
          <div className="col-12 col-md-5">
            <p className="fs-60 fw-900 opacity-10">02</p>
            <h4>Push the button</h4>
            <p>
              With a click on the button, a contact / lead is created in your CRM system with the info from the form, without you even having to be logged in to the CRM system.
              Contacts can be linked to Company / Account CRM records through a lookup in the form as well.
            </p>
          </div>
          <div className="col-12 col-md-7">
            <img className="rounded shadow-2" src="/images/save-contact.png" />
            {/* <img class="rounded shadow-2" src="/images/2-settings.gif" alt="..."> */}
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <header className="section-header">
          <small>Less admin, more selling</small>
          <h2>Other benefits</h2>
          <hr />
          {/* <p class="lead">2, not 3.</p> */}
        </header>
        <div className="row gap-y align-items-center mb-50">
          <div className="col-12 col-md-7">
            <img className="rounded shadow-2" src="/images/known-contact.png" alt="..." />
          </div>
          <div className="col-12 col-md-5">
            <p className="fs-60 fw-900 opacity-10">01</p>
            <h4>Don't waste time on known contacts</h4>
            <p>When you visit a person profile on LinkedIn or Sales Navigator, LeadExporter will check if the contact already exists in
              your CRM system. If it does, core CRM info shows in the sidebar, together with a link to the CRM record.
            </p>
          </div>
        </div>
        <hr />
        <div className="row gap-y align-items-center mb-50">
          <div className="col-12 col-md-5">
            <p className="fs-60 fw-900 opacity-10">02</p>
            <h4>Fully configurable form</h4>
            <p>
              Decide which fields show up in the form, in what order and which are required. Add custom fields and choose of multiple supported field formats.
              Simply map the fields to your CRM system fields.
            </p>
          </div>
          <div className="col-12 col-md-7">
            <img className="rounded shadow-2" src="/images/dashboard.png" alt="..." />
          </div>
        </div>
      </div>
    </section>
  </main>
</div>


)

export default HowitWorks;
