import React, { useState, useReducer, useEffect } from 'react';

const Footer = ({}) => (
    <footer className="site-footer">
        <div className="container">
            <div className="row gap-y">
                <div className="col-12 col-md-5">
                    <h6 className="heading-alt text-uppercase fs-14 mb-3">LeadExporter.io</h6>

                    <br />
                        <p className="text-light">Copyright © {(new Date().getFullYear())} <a className="text-light" href="/copyright">LeadExporter.io</a>. All rights reserved.</p>
          </div>

                    <div className="col-12 col-md-2">
                        <h6 className="heading-alt text-uppercase fs-14 mb-3">Company</h6>
                        <div className="nav flex-column">
                            <a className="nav-link" href="/contact">Contact</a>
                            <a className="nav-link" href="/privacy">Privacy</a> 

                        </div>
                    </div>


                    <div className="col-12 col-md-2">
                        <h6 className="heading-alt text-uppercase fs-14 mb-3">Help</h6>
                        <div className="nav flex-column">
                            <a className="nav-link" href="/docs">Documentation</a>
                            <a className="nav-link" href="/faq">FAQ</a>
                        </div>
                    </div>


                </div>
            </div>
    </footer> 
);
export default Footer;
